import { createRouter, createWebHistory } from 'vue-router';
import {getCookie } from '../cookieUtils';

const routes = [
  { path: '/', component: () => import('../views/HomePage.vue') },
  { path: '/add-storage-item', component: () => import('../views/AddStorageItem.vue') },
  { path: '/add-recipe', component: () => import('../views/AddRecipe.vue') },
  { path: '/edit-recipe/:id', component: () => import('../views/AddRecipe.vue'), name: 'RecipeEdit', props: true },
  { path: '/agb', component: () => import('../views/AGBPage.vue') },
  { path: '/datenschutz', component: () => import('../views/DatenschutzPage.vue') },
  { path: '/impressum', component: () => import('../views/ImpressumPage.vue') },
  { path: '/recipe/:id', component: () => import('../views/RecipeDetail.vue'), name: 'RecipeDetail' },
  { path: '/login', component: () => import('../views/UserLogin.vue'), name: 'UserLogin' },
  { path: '/register', component: () => import('../views/UserRegister.vue'), name: 'UserRegister' },
  { path: '/forgot-password', component: () => import('../views/ForgotPassword.vue'), name: 'ForgotPassword' },
  { path: '/mine', component: () => import('../views/MySpace.vue'), name: 'Mine' }
];

const router = createRouter({
  history: createWebHistory(),
  routes
});

router.beforeEach((to, from, next) => {
  const token = getCookie('token');
  
  if ((to.path === '/add-recipe' || to.path === '/add-storage-item') && !token) {
    next({ name: 'UserLogin' });
  } else {
    next();
  }
});

export default router;